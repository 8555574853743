/*eslint no-use-before-define: "off"*/
import type {API, Mongo} from '@shelf/types';
import type {SelfService as SelfServiceDDB} from '@shelf/types-ddb';
import type {NextParsedUrlQuery} from 'next/dist/server/request-meta';
import type {TemplateDefinition as TemplateDefinition_} from '@shelf/types/lib/api/self-service';
import type {SSPApi} from '@shelf/data-hooks';
import type {
  ArticlePageRoutesData,
  FolderPageRoutesData,
  HomePageRoutesData,
  NotGemPageRoutesData,
} from '../dataForRoutes/types';
import type {Gems} from '@shelf/types/lib/mongo';
import type {GemForTree} from '../../components/ArticlesTree/hooks/useFlatTree';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface ProcessEnv {
      DATADOG_CLIENT_TOKEN: string;
      DATADOG_RUM_APPLICATION_ID: string;
      DATADOG_RUM_CLIENT_TOKEN: string;
      ENVIRONMENT: string;
    }
  }
}

export type Category = {name: string; count: number};

export type CategoryV2 = {
  categoryId: string;
  categoryName: string;
  categoryLabel?: string;
  topParentCategoryName?: string;
  count?: number;
};

type BasicGem = {
  _id: string;
  type: Mongo.Gems.GemProps.Type;
  accountId?: string;
  ownerId?: string;
  title: string;
  locations: Mongo.Gems.GemProps.GemLocation[];
  contentUpdatedAt: string;
  userId: string;
  userFullName: string;
  ownerUsername?: string;
  userAvatarURL: string;
  description: string;
};

export type ReduxState = {
  sidebarMobileVisible: boolean;
  term?: string;
  loading?: boolean;
  page?: string;
  anonUserId: undefined | string;
  isAppInPreviewMode?: boolean;
  sspPageViewedEventId?: string;
};

type Gem = {
  slug?: string;
  articleThumbnailURL?: string;
  url?: string;
  createdAt: string | number;
} & BasicGem;

type GemTag = {createdAt: string; ownerId: string; tag: string};

export type GemTarget = {
  tags: GemTag[];
  wikiText: string;
  path?: string;
  categories?: CategoryV2[];
  badge?: string;
  createdAt: string | number;
  ownerUsername?: string;
  contentUpdatedByUserFullName?: string;
} & Gem;

type OgImage = {url: string; width?: number; height?: number; alt?: string};

export type OpenGraph = {
  url?: string;
  type?: string;
  title?: string;
  description?: string | undefined;
  images?: OgImage[];
  defaultImageHeight?: number;
  defaultImageWidth?: number;
  locale?: string;
  site_name?: string;
  article?: {
    publishedTime?: string;
    modifiedTime?: string;
    expirationTime?: string;
    authors?: string[];
    section?: string;
    tags?: string[];
  };
};

export type TToggleSideBar = (event: any) => void;

export type AppInfo = ApiPageResponse['appConfig'] & {title?: string};

export type SEOProviderProps = {
  title?: string;
  description?: string;
  openGraph?: OpenGraph;
  children?: React.ReactNode | React.ReactNode[];
};

export type SSPArticle = {
  gemId: string;
  createdAt: string;
  ownerUsername: string;
  title?: string;
  contentUpdatedAt?: string;
  description?: string;
  contentUpdatedByUserFullName?: string;
  url?: string;
  category?: string;
  tags?: string[];
};

export type ApplicationContext = (
  | HomePageRoutesData
  | FolderPageRoutesData
  | ArticlePageRoutesData
  | NotGemPageRoutesData
) & {
  gem?: ArticlePageRoutesData['gem'];
  sidebarMobileVisible: boolean;
  sideSectionItemsFetching?: boolean;
  withoutNavigation: boolean;
  anonUserId?: string;
  term?: string;
};

export type ApiPageResponse = API.SelfService.PageResponse;

export type TemplateDefinition = TemplateDefinition_ & {isPreviewed?: boolean};

export type CurrentGem =
  | HomePageResponse['currentGem']
  | FolderPageResponse['currentGem']
  | ArticlePageResponse['currentGem']
  | ArticlePageDTResponse['currentGem'];

export type SSPArticleWithURL = API.SelfService.ContentGemItem;

export type Query = {
  appInfo: AppInfo;
  categories: {name: string; count: number; url: string}[];
  currentGem: CurrentGem;
  folders: PageFolderItem[];
  gems: PageGemItem[];
  mostPopularArticles?: SSPMostViewedArticles;
  pageType: PageTypeRecord;
  pageTypeString: PageTypeString;
  queryUrl: {term?: string; page?: string; tag?: string; category?: string; categoryId?: string};
  tags: {count: number; name: string; url: string}[]; // TODO: Add shared type
  templateDefinition: TemplateDefinition;
  url: string;
  urlReq: string;
  currentStep?: CreateJourneySSPResponse['initialStep'];
  journeyId?: CreateJourneySSPResponse['journeyId'];
  firstStepId?: CreateJourneySSPResponse['firstStepId'];
  isOpenDTLinkInNewTabEnabled?: CreateJourneySSPResponse['isOpenDTLinkInNewTabEnabled'];
  searchResult?: API.SelfService.ContentGemSearchResult;
  searchQuery?: NextParsedUrlQuery;
};

export type PageTypeRecord = Record<
  'root' | 'page' | 'pageDT' | 'folder' | 'search' | '404',
  boolean
>;

export type PageTypeString = SelfServiceDDB.RouteType;

type PageArticle = {_id: string; title: string; articleThumbnailURL?: string; slug: string};

export type ArticleLinkWidget = PageArticle & {url: string; createdAt?: string};

type PageFolder = {_id: string; slug: string; title: string; gems: FolderArticle[]};

export type PageFolderWithUrl = PageFolder & {url: string};

export type FolderArticle = {_id: string; title: string; slug: string};

type FolderWidgetArticle = {_id: string; title: string; url: string};

export type FolderWidget = PageFolder & {url: string; children: FolderWidgetArticle[]};

// TODO: add/re-use from libs types

export enum GemTypes {
  Note = 'Note',
  SSL = 'Self-Service Library',
  'Directory' = 'Directory',
  'DecisionTree' = 'Decision Tree',
}

export type HomePageResponse = BasePageResponse & {currentGem: HomePageCurrentGem};

export type FolderPageResponse = BasePageResponse & {currentGem: FolderPageCurrentGem};

export type ArticlePageResponse = BasePageResponse & {currentGem: ArticlePageCurrentGem};

export type ArticlePageDTResponse = BasePageResponse & {currentGem: ArticlePageDTCurrentGem};

type BasePageResponse = {appConfig: AppInfo};

export type FooterSettings = AppInfo['settings']['footer'];

export type HomePageCurrentGem = BaseCurrentGem & {type: GemTypes.SSL};

export type ArticlePageCurrentGem = BaseCurrentGem & {
  type: GemTypes.Note | GemTypes.DecisionTree;
  wikiText?: string;
  userAvatarURL?: string;
  tags?: GemTag[];
  categories?: CategoryV2[];
};

export type ArticlePageDTCurrentGem = BaseCurrentGem & {
  type: GemTypes.DecisionTree;
  userAvatarURL?: string;
  tags?: GemTag[];
  categories?: CategoryV2[];
};

export type FolderPageCurrentGem = BaseCurrentGem & {type: GemTypes.Directory};

type BaseCurrentGem = {
  _id: string;
  contentUpdatedAt: string;
  createdAt: string;
  description?: string;
  folders?: PageFolderItem[];
  gems?: PageGemItem[];
  ownerUsername: string;
  previewImageURL?: string;
  title: string;
  type: GemTypes;
  breadCrumbs: APIBreadCrumbs;
};

export type PageFolderItem = {_id: string; slug: string; title: string; previewImageURL?: string};

export type APIBreadCrumbs = {title: string; slug: string}[];
export type PageBreadCrumbs = {title: string; slug: string; href: string}[];

type GemWithTitle = {_id: string; title: string};

export type PageGemItem = GemWithTitle & {
  articleThumbnailURL?: string;
  slug: string;
  createdAt: string;
};

export type FeedbackValue = 'upvote' | 'downvote';

export type ArticleTree = {
  gems: GemForTree[];
  folders: GemForTree[];
  library?: Pick<Gems.Gem, '_id' | 'title'> & {slug: string};
};

export type CreateJourneySSPResponse = Awaited<ReturnType<typeof SSPApi.createDTJourney>>;

export type SSPLibsGemsRecommendationResponse = Awaited<
  ReturnType<typeof SSPApi.gemRecommendations>
>;

export type SSPGetRouteTemplateResponse = Awaited<ReturnType<typeof SSPApi.getRouteTemplate>>;

export type SSPMostViewedArticles = Awaited<ReturnType<typeof SSPApi.mostViewedArticlesByLanguage>>;
